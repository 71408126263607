import React, { useState } from 'react';
import * as tConst from '../common/Constants';
import {  Card, Col, Row, Radio, Space, Input, Dropdown, Menu, Button } from 'antd';
import { todayIso } from '../utils/date';
import * as AzureService from "../api/ticketsApiAzure";


const { TextArea } = Input;


const TriageForm = ({ item, auth }) => {
    const [triageDecision, setTriageDecision] = useState('Ready4Triage');
    // todo populate this with the Triage text from the ticket
    const [triageText, setTriageText] = useState('');
    const [triageList, setTriageList] = useState([]);
    const [triageDateList, setTriageDateList] = useState([]);
    const link = tConst.AZURE_DEVOPS_BASE_TICKET_URI + item.id;

    const selectedTriage = tConst.TRIAGE_TEXT.filter((triageText) => {
        return triageText.triage === triageDecision;
    });

    const handleSave = () => {
        const _newTriageObject = {
            date: todayIso(),
            triage: triageDecision,
            text: triageText,
        };
        setTriageDateList(new Set([...triageDateList, _newTriageObject.date]));
        setTriageList([...triageList.filter(item => item.date !== _newTriageObject.date), _newTriageObject]);
        
        const data = JSON.stringify([
            {
              "op": "replace",
              "path": "/fields/"+"Custom.TriageData",
              "value": JSON.stringify(triageList)
            }
          ])  

          return AzureService.updateTicket(auth, item, data).then((response) => {
            console.log("Updated Ticket: " +JSON.stringify(response));
        })


    };

    return (
        <>
            <div>
                <h2>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        [{item.id}]
                    </a>
                    {item.fields["System.Title"]}
                </h2>
            </div>
            <hr />
            <Row>
                <Col span={10}>
                    <div>
                        <div>
                            <Card
                                title="Description"
                                size="small"
                                style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item.fields["System.Description"] }} />
                            </Card>

                            <Card
                                title="Repro steps"
                                size="small"
                                style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item.fields["Microsoft.VSTS.TCM.ReproSteps"] }} />
                            </Card>
                            <Card
                                title="Business justification"
                                size="small"
                                style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item.fields["Microsoft.VSTS.CMMI.Justification"] }} />
                            </Card>
                        </div>
                    </div>
                </Col>
                <Col span={1}>
                    <div></div>
                </Col>
                <Col span={12}>
                    <div>
                        <h2>Triage</h2>
                        <Space>
                            <Radio.Group value={triageDecision} onChange={(e) => setTriageDecision(e.target.value)}>
                                <Radio.Button value="Ready4Triage">Ready4Triage</Radio.Button>
                                {tConst.TRIAGE_TEXT.map((triageText, index) => {
                                    return (
                                        <Radio.Button key={index} value={triageText.triage}>{triageText.triage}</Radio.Button>
                                    );
                                })}
                            </Radio.Group>
                        </Space>
                    </div>
                    <Card
                        title="Triage Text"
                        size="small"
                        style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                    >
                        <Dropdown
                            overlay={
                                <Menu>
                                    {selectedTriage && selectedTriage.length > 0 && selectedTriage[0]?.standardTexts?.map((item, index) => {
                                        return (
                                            <Menu.Item key={index + 1} onClick={() => setTriageText(item.value)}>{item.name}</Menu.Item>
                                        );
                                    })}
                                </Menu>
                            }
                        >
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                Insert default text
                            </a>
                        </Dropdown>

                        <TextArea
                            value={triageText}
                            onChange={(e) => setTriageText(e.target.value)}
                            placeholder="Write a triage text or insert a default text from the template buttons"
                            autoSize={{
                                minRows: 3,
                                maxRows: 10,
                            }}
                        />
                        <br />
                        <Button type="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </Card>
                    <Card
                        title="Old Triage Text"
                        size="small"
                        style={{ width: '100%', marginTop: 16, textAlign: 'left' }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: item.fields["Custom.OldTriageText"] }} />
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default TriageForm;